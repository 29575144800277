import { selectAffiliation } from '../index';

//! what if we just exported this function and not selectAffiliation? Would that solve our cycle dependency issue????
const resolveAffiliation = () => {
  try {
    return selectAffiliation.value;
  } catch (error) {
    console.log(error);
    return 'friendly';
  }
};

// * MODIFIER 1 OBJECT * //
// * Sector 1 Modifiers reflect the specific capability of a unit (Armored, Bridging, Sniper, etc) * //
const mod1Object = {
  None: {
    type: 'Default',
    affiliation: {
      friendly: {
        path_1: {
          d: '',
        },
      },
      get friendlyTemplated() {
        return this.friendly;
      },
      get hostile() {
        return this.friendly;
      },
      get hostileTemplated() {
        return this.friendly;
      },
      get unknown() {
        return this.friendly;
      },
      get pending() {
        return this.friendly;
      },
      get neutral() {
        return this.friendly;
      },
    },
  },
  'Search and Rescue': {
    type: 'Aviation',
    affiliation: {
      friendly: {
        text_1: {
          symbolText: 'SAR',
          x: '100',
          y: '72',
          fontSize: '22',
          textAnchor: 'middle',
        },
      },
      get friendlyTemplated() {
        return this.friendly;
      },
      get hostile() {
        const propertyToModify = JSON.parse(JSON.stringify(this.friendly));
        propertyToModify.text_1.fontSize = '19';
        propertyToModify.text_1.y = '69';
        return propertyToModify;
      },
      get hostileTemplated() {
        return this.hostile;
      },
      get unknown() {
        return this.hostile;
      },
      get pending() {
        return this.hostile;
      },
      get neutral() {
        return this.hostile;
      },
    },
  },
  Sniper: {
    type: 'Infantry',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'M75,60 l20,0 M100,76 l0,-18 M125,60 l-20,0',
            strokeWidth: 3,
          },
        },
      };
    },
  },
  Chemical: {
    type: 'CBRN',
    affiliation: {
      friendly: {
        text_1: {
          symbolText: 'C',
          x: '100',
          y: '72',
          fontSize: '22',
          textAnchor: 'middle',
        },
      },
      get friendlyTemplated() {
        return this.friendly;
      },
      get hostile() {
        const propertyToModify = JSON.parse(JSON.stringify(this.friendly));
        propertyToModify.text_1.fontSize = '19';
        propertyToModify.text_1.y = '69';
        return propertyToModify;
      },
      get hostileTemplated() {
        return this.hostile;
      },
      get unknown() {
        return this.hostile;
      },
      get pending() {
        return this.hostile;
      },
      get neutral() {
        return this.hostile;
      },
    },
  },
  Armored: {
    type: 'Armor',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'm 90,60 20,0 c 10,0 10,15 0,15 L 90,75 C 80,75 80,60 90,60',
          },
        },
      };
    },
  },
  Biological: {
    type: 'CBRN',
    affiliation: {
      friendly: {
        text_1: {
          symbolText: 'B',
          x: '100',
          y: '72',
          fontSize: '22',
          textAnchor: 'middle',
        },
      },
      get friendlyTemplated() {
        return this.friendly;
      },
      get hostile() {
        const propertyToModify = JSON.parse(JSON.stringify(this.friendly));
        propertyToModify.text_1.fontSize = '19';
        propertyToModify.text_1.y = '69';
        return propertyToModify;
      },
      get hostileTemplated() {
        return this.hostile;
      },
      get unknown() {
        return this.hostile;
      },
      get pending() {
        return this.hostile;
      },
      get neutral() {
        return this.hostile;
      },
    },
  },
  Bridging: {
    type: 'Engineer',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'm 80,75 5,-5 30,0 5,5 m -40,-20 5,5 30,0 5,-5',
          },
        },
      };
    },
  },
  'Command and Control': {
    type: 'Command',
    affiliation: {
      friendly: {
        text_1: {
          symbolText: 'C2',
          x: '100',
          y: '72',
          fontSize: '22',
          textAnchor: 'middle',
        },
      },
      get friendlyTemplated() {
        return this.friendly;
      },
      get hostile() {
        const propertyToModify = JSON.parse(JSON.stringify(this.friendly));
        propertyToModify.text_1.fontSize = '19';
        propertyToModify.text_1.y = '65';
        return propertyToModify;
      },
      get hostileTemplated() {
        return this.hostile;
      },
      get unknown() {
        return this.hostile;
      },
      get pending() {
        return this.hostile;
      },
      get neutral() {
        return this.hostile;
      },
    },
  },
  Headquarters: {
    type: 'Command',
    affiliation: {
      friendly: {
        path_1: {
          d: 'M25,70 l150,0',
        },
      },
      get friendlyTemplated() {
        return this.friendly;
      },
      hostile: {
        path_1: {
          d: 'M59,70 l85,0',
        },
      },
      get hostileTemplated() {
        return this.hostile;
      },
      unknown: {
        path_1: {
          d: 'M45,70 l110,0',
        },
      },
      get pending() {
        return this.unknown;
      },
      get neutral() {
        return this.unknown;
      },
    },
  },
  Water: {
    type: 'Quartermaster',
    affiliation: {
      friendly: {
        path_1: {
          d: 'm 90,55 20,0 m -10,10 0,-10 m -25,10 40,0 c 10,0 15,5 15,10',
          strokeWidth: 3,
        },
      },
      get friendlyTemplated() {
        return this.friendly;
      },
      get hostile() {
        const propertyToModify = JSON.parse(JSON.stringify(this.friendly));
        propertyToModify.path_1.transform = 'translate(10,0) scale(0.9)';
        return propertyToModify;
      },
      get hostileTemplated() {
        return this.hostile;
      },
      get unknown() {
        return this.hostile;
      },
      get pending() {
        return this.hostile;
      },
      get neutral() {
        return this.hostile;
      },
    },
  },
  Satellite: {
    type: 'Signal',
    affiliation: {
      friendly: {
        path_1: {
          d: 'M32.8,47H2V2.1h30.8V47z M107.8,2H77v44.8h30.8V2z M70.2,7.3H39.5v34.5h30.7V7.3z M14.3,24.5h80.9  M54.3,23.9v33.3',
          strokeWidth: 3,
          transform: 'translate(82,54) scale(0.34)',
          fill: 'black',
        },
        path_2: {
          d: 'M30.4,67.7c16-12,34-12,50,0',
          transform: 'translate(82,54) scale(0.34)',
          strokeWidth: 3,
        },
      },
      get friendlyTemplated() {
        return this.friendly;
      },
      get hostile() {
        const propertyToModify = JSON.parse(JSON.stringify(this.friendly));
        propertyToModify.path_1.transform = 'translate(82,52) scale(0.34)';
        propertyToModify.path_2.transform = 'translate(82,52) scale(0.34)';
        return propertyToModify;
      },
      get hostileTemplated() {
        return this.hostile;
      },
      get unknown() {
        return this.hostile;
      },
      get pending() {
        return this.hostile;
      },
      get neutral() {
        return this.hostile;
      },
    },
  },
  Foraging: {
    type: 'Activity',
    affiliation: {
      friendly: {
        path_1: {
          d: 'M 12.5,3.5 a 22.5,22.5 0 0,1 0,43 a 22.5,22.5 0 1,0 0,-43 z',
          strokeWidth: '6',
          transform: 'translate(110,75) scale(-0.4,-0.4)',
        },
      },
      get friendlyTemplated() {
        return this.friendly;
      },
      get hostile() {
        return this.friendly;
      },
      get hostileTemplated() {
        return this.friendly;
      },
      get unknown() {
        return this.friendly;
      },
      get pending() {
        return this.friendly;
      },
      get neutral() {
        const propertyToModify = JSON.parse(JSON.stringify(this.friendly));
        propertyToModify.path_1.transform = 'translate(110,77.5) scale(-0.4,-0.4)';
        return propertyToModify;
      },
    },
  },
};

export default mod1Object;
