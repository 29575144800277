// * UNIT AFFILIATION OBJECT * //
const affiliationOutlineObject = {
  get friendly() {
    return {
      templated: false,
      d: 'M25,50 l150,0 0,100 -150,0 z',
      fill: 'rgb(128,224,255)',
      flying: 'M 155,150 C 155,50 115,30 100,30 85,30 45,50 45,150',
      activity: 'm 160,135 0,15 15,0 0,-15 z m -135,0 15,0 0,15 -15,0 z m 135,-85 0,15 15,0 0,-15 z m -135,0 15,0 0,15 -15,0 z',
      installation: 'M85,48 85,40 115,40 115,48 100,46 Z',
    };
  },
  get friendlyTemplated() {
    return {
      templated: true,
      d: 'M25,50 l150,0 0,100 -150,0 z',
      fill: 'rgb(128,224,255)',
      fill_2: 'none',
      stroke_2: 'rgb(239, 239, 239)',
      strokeWidth_2: '5',
      strokeDashArray_2: '4,4',
      flying: 'M 155,150 C 155,50 115,30 100,30 85,30 45,50 45,150',
      activity: 'm 160,135 0,15 15,0 0,-15 z m -135,0 15,0 0,15 -15,0 z m 135,-85 0,15 15,0 0,-15 z m -135,0 15,0 0,15 -15,0 z',
      installation: 'M85,48 85,40 115,40 115,48 100,46 Z',
    };
  },
  get hostile() {
    return {
      templated: false,
      d: 'M 100,28 L172,100 100,172 28,100 100,28 Z',
      fill: 'rgb(255,128,128)',
      flying: 'M 45,150 L45,70 100,20 155,70 155,150',
      activity: 'M 100 28 L 89.40625 38.59375 L 100 49.21875 L 110.59375 38.59375 L 100 28 z M 38.6875 89.3125 L 28.0625 99.9375 L 38.6875 110.53125 L 49.28125 99.9375 L 38.6875 89.3125 z M 161.40625 89.40625 L 150.78125 100 L 161.40625 110.59375 L 172 100 L 161.40625 89.40625 z M 99.9375 150.71875 L 89.3125 161.3125 L 99.9375 171.9375 L 110.53125 161.3125 L 99.9375 150.71875 z',
      installation: 'M85,40 85,18 115,18 115,40 100,24 Z',
    };
  },
  get hostileTemplated() {
    return {
      templated: true,
      d: 'M 100,28 L172,100 100,172 28,100 100,28 Z',
      fill: 'rgb(255,128,128)',
      fill_2: 'none',
      stroke_2: 'rgb(239, 239, 239)',
      strokeWidth_2: '5',
      strokeDashArray_2: '4,4',
      flying: 'M 45,150 L45,70 100,20 155,70 155,150',
      activity: 'M 100 28 L 89.40625 38.59375 L 100 49.21875 L 110.59375 38.59375 L 100 28 z M 38.6875 89.3125 L 28.0625 99.9375 L 38.6875 110.53125 L 49.28125 99.9375 L 38.6875 89.3125 z M 161.40625 89.40625 L 150.78125 100 L 161.40625 110.59375 L 172 100 L 161.40625 89.40625 z M 99.9375 150.71875 L 89.3125 161.3125 L 99.9375 171.9375 L 110.53125 161.3125 L 99.9375 150.71875 z',
      installation: 'M85,40 85,18 115,18 115,40 100,24 Z',
    };
  },
  get unknown() {
    return {
      templated: false,
      d: 'M63,63 C63,20 137,20 137,63 C180,63 180,137 137,137 C137,180 63,180 63,137 C20,137 20,63 63,63 Z',
      fill: 'rgb(255,255,128)',
      flying: 'M 65,150 c -55,0 -50,-90 0,-90 0,-50 70,-50 70,0 50,0 55,90 0,90',
      activity: 'M 107.96875 31.46875 L 92.03125 31.71875 L 92.03125 46.4375 L 107.71875 46.4375 L 107.96875 31.46875 z M 47.03125 92.5 L 31.09375 92.75 L 31.09375 107.5 L 46.78125 107.5 L 47.03125 92.5 z M 168.4375 92.5 L 152.5 92.75 L 152.5 107.5 L 168.1875 107.5 L 168.4375 92.5 z M 107.96875 153.5625 L 92.03125 153.8125 L 92.03125 168.53125 L 107.71875 168.53125 L 107.96875 153.5625 z',
      installation: 'M85,30.75 85,20.75 115,20.75 115,30.75 100,26.75 Z',
    };
  },
  get pending() {
    return {
      templated: true,
      d: 'M63,63 C63,20 137,20 137,63 C180,63 180,137 137,137 C137,180 63,180 63,137 C20,137 20,63 63,63 Z',
      fill: 'rgb(255,255,128)',
      fill_2: 'none',
      stroke_2: 'rgb(239, 239, 239)',
      strokeWidth_2: '5',
      strokeDashArray_2: '4,4',
      flying: 'M 65,150 c -55,0 -50,-90 0,-90 0,-50 70,-50 70,0 50,0 55,90 0,90',
      activity: 'M 107.96875 31.46875 L 92.03125 31.71875 L 92.03125 46.4375 L 107.71875 46.4375 L 107.96875 31.46875 z M 47.03125 92.5 L 31.09375 92.75 L 31.09375 107.5 L 46.78125 107.5 L 47.03125 92.5 z M 168.4375 92.5 L 152.5 92.75 L 152.5 107.5 L 168.1875 107.5 L 168.4375 92.5 z M 107.96875 153.5625 L 92.03125 153.8125 L 92.03125 168.53125 L 107.71875 168.53125 L 107.96875 153.5625 z',
      installation: 'M85,30.75 85,20.75 115,20.75 115,30.75 100,26.75 Z',
    };
  },
  get neutral() {
    return {
      templated: false,
      d: 'M45,45 l110,0 0,110 -110,0 z',
      fill: 'rgb(170, 255, 170)',
      flying: 'M 45,150 L 45,30,155,30,155,150',
      activity: 'm 140,140 15,0 0,15 -15,0 z m -80,0 0,15 -15,0 0,-15 z m 80,-80 0,-15 15,0 0,15 z m -80,0 -15,0 0,-15 15,0 z',
      installation: 'M85,43 85,35 115,35 115,43 100,41 Z',
    };
  },
};

export default affiliationOutlineObject;
