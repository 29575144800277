// * MILITARY SYMBOLS OBJECT * //
import { selectAffiliation } from '../index';

const resolveAffiliation = () => {
  try {
    return selectAffiliation.value;
  } catch (error) {
    console.log(error);
    return 'friendly';
  }
};

const militarySymbolsObject = {
  'Default Land Unit': {
    type: 'Land Unit',
    affiliation: {
      friendly: {
        path_1: {
          d: '',
        },
      },
      get friendlyTemplated() {
        return this.friendly;
      },
      get hostile() {
        return this.friendly;
      },
      get hostileTemplated() {
        return this.friendly;
      },
      get unknown() {
        return this.friendly;
      },
      get pending() {
        return this.friendly;
      },
      get neutral() {
        return this.friendly;
      },
    },
  },
  Infantry: {
    type: 'Land Unit',
    affiliation: {
      friendly: {
        path: {
          d: 'M25,50 L175,150 M25,150 L175,50',
        },
      },
      get friendlyTemplated() {
        return this.friendly;
      },
      hostile: {
        path: {
          d: 'M65,65 L137,137 M65, 137 L137, 65',
        },
      },
      get hostileTemplated() {
        return this.hostile;
      },
      unknown: {
        path: {
          d: 'M50,65L150,135M50,135L150,65',
        },
      },
      get pending() {
        return this.unknown;
      },
      neutral: {
        path: {
          d: 'M45,45L155,155M45,155L155,45',
        },
      },
    },
  },
  Checkpoint: {
    type: 'Graphic Control Measure',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'm 60,45 80,0 m -40,55 -40,-55 0,-105 80,0 0,105 z',
          },
          text_1: {
            symbolText: 'CKP',
            x: '100',
            y: '-20',
            fontSize: '30',
            textAnchor: 'middle',
          },
        },
      };
    },
  },
  'Mechanized Armored Infantry': {
    type: 'Land Unit',
    affiliation: {
      friendly: {
        path: {
          d: 'M25,50 L175,150 M25,150 L175,50 M125,80 C150,80 150,120 125,120 L75,120 C50,120 50,80 75,80 Z',
        },
      },
      get friendlyTemplated() {
        return this.friendly;
      },
      hostile: {
        path: {
          d: 'M60,70L140,130M60,130L140,70 M125,80 C150,80 150,120 125,120 L75,120 C50,120 50,80 75,80 Z',
        },
      },
      get hostileTemplated() {
        return this.hostile;
      },
      unknown: {
        path: {
          d: 'M50,65L150,135M50,135L150,65 M125,80 C150,80 150,120 125,120 L75,120 C50,120 50,80 75,80 Z',
        },
      },
      get pending() {
        return this.unknown;
      },
      neutral: {
        path: {
          d: 'M45,45L155,155M45,155L155,45 M125,80 C150,80 150,120 125,120 L75,120 C50,120 50,80 75,80 Z',
        },
      },
    },
  },
  'Target Reference Point': {
    type: 'Graphic Control Measure',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'm 60,45 100,0 m -50,-50 0,100',
          },
        },
      };
    },
  },
  'Armored Track Unit': {
    type: 'Land Unit',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path: {
            d: 'M125,80 C150,80 150,120 125,120 L75,120 C50,120 50,80 75,80 Z',
          },
        },
      };
    },
  },
  'Field Artillery': {
    type: 'Land Unit',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path: {
            d: 'M85,100a15,15 0 1,0 30,0a15,15 0 1,0 -30,0',
            fill: 'black',
          },
        },
      };
    },
  },
  Cavalry: {
    type: 'Land Unit',
    affiliation: {
      friendly: {
        path: {
          d: 'M25,150L175,50',
        },
      },
      get friendlyTemplated() {
        return this.friendly;
      },
      hostile: {
        path: {
          d: 'M65, 137 L137, 65 Z',
        },
      },
      get hostileTemplated() {
        return this.hostile;
      },
      unknown: {
        path: {
          d: 'M50,135L150,65',
        },
      },
      get pending() {
        return this.unknown;
      },
      neutral: {
        path: {
          d: 'M45,155L155,45',
        },
      },
    },
  },
  Engineer: {
    type: 'Land Unit',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'M60,120 L60,80 140,80 140,120 M100,80 L100,110',
          },
        },
      };
    },
  },
  'Antipersonnel Mines': {
    type: 'Graphic Control Measure',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'M25 65v70h150V65z',
            stroke: 'green',
          },
          circle_1: {
            cx: '55',
            cy: '100',
            r: '15',
            fill: 'green',
            stroke: 'green',
          },
          circle_2: {
            cx: '100',
            cy: '100',
            r: '15',
            fill: 'green',
            stroke: 'green',
          },
          circle_3: {
            cx: '145',
            cy: '100',
            r: '15',
            fill: 'green',
            stroke: 'green',
          },
          path_2: {
            d: 'm 80,75 10.6,13.3 m 18.8,0 10.6,-13.3',
            stroke: 'green',
          },
          path_3: {
            d: 'm 125,75 10.6,13.3 m 18.8,0 10.6,-13.3',
            stroke: 'green',
          },
          path_4: {
            d: 'm 35,75 10.6,13.3 m 18.8,0 10.6,-13.3',
            stroke: 'green',
          },
        },
      };
    },
  },
  'Wire Obstacle (Single Strand Concertina)': {
    type: 'Graphic Control Measure',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'M-70 65h364M-49 25c-7 0-13 9-13 19s6 20 14 20 13-9 13-20-7-19-14-19zm46 1c-7 0-13 8-13 19s6 19 14 19 13-8 13-19S5 25-3 25zm47 0c-8 0-13 8-13 19s6 19 13 19 13-8 13-19-6-19-13-19zm45 0c-7 0-13 9-13 20s6 19 14 19 13-9 13-19-6-20-14-20zm46 0c-7 0-13 9-13 19s6 20 13 20 14-9 14-20-7-19-14-19zm46 0c-7 0-13 8-13 19s6 19 13 19 14-8 14-19-6-19-14-19zm47 0c-7 0-13 9-13 19s6 20 13 20 14-9 14-20-7-19-14-19zm46 0c-7 0-13 8-13 19s6 19 14 19 12-8 12-19-6-19-13-19z',
            stroke: 'green',
          },
        },
      };
    },
  },
  'Antitank Gun - Light': {
    type: 'Equipment',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'm 85,140 15,-15 15,15 m -15,-80 0,65 m -15,-45 0,40 m 30,-40 0,40z m -30,10 30,0z',
          },
        },
      };
    },
  },
  'Antitank Gun - Medium': {
    type: 'Equipment',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'm 85,140 15,-15 15,15 m -15,-80 0,65 m -15,-45 0,40 m 30,-40 0,40z m -30,10 30,0z m 0,10 30,0z',
          },
        },
      };
    },
  },
  'Antitank Gun - Heavy': {
    type: 'Equipment',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'm 85,140 15,-15 15,15 m -15,-80 0,65 m -15,-45 0,40 m 30,-40 0,40z m -30,10 30,0z m 0,10 30,0z m 0,10 30,0z',
          },
        },
      };
    },
  },
  CBRN: {
    type: 'Land Unit',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'm 80,120 c 0,-20 10,-32 50,-35 m -10,35 C 120,100 110,88 70,85',
          },
          circle_1: {
            cx: '74',
            cy: '90',
            r: '6',
          },
          circle_2: {
            cx: '126',
            cy: '90',
            r: '6',
          },
        },
      };
    },
  },
  'Unmanned Aerial Vehicle (UAV)': {
    type: 'Equipment',
    flightCapable: true,
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'm 60,84 40,20 40,-20 0,8 -40,25 -40,-25 z',
            fill: 'black',
          },
        },
      };
    },
  },
  'Attack by Fire': {
    type: 'Tactical Mission Task',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'm 0 45 h 120 l -27 -15 m 27 15 l -27 15 m -93 35 v -100 l -30 -30 m 30 129.5 l -30 30',
            strokeLinejoin: 'round',
            transform: 'translate(20,50)',
          },
        },
      };
    },
  },
  'Mass Demonstration': {
    type: 'Activity - Incident',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          text: {
            symbolText: 'MASS',
            x: '100',
            y: '115',
            textAnchor: 'middle',
            fontSize: '35',
            fontWeight: 'bold',
          },
        },
      };
    },
  },
  Explosion: {
    type: 'Activity - Incident',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'm 110,55 5,20 15,-10 0,15 15,5 -15,10 15,10 -15,5 5,15 -20,-5 -5,20 -10,-15 -10,20 -5,-25 -20,10 5,-15 L 55,105 70,95 60,85 70,80 70,65 85,75 90,55 100,70 z',
            transform: 'translate(40,45) scale(0.6)',
          },
        },
      };
    },
  },
  'Class I Supply Point': {
    type: 'Graphic Control Measure',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'M60 30h80M60 45h80m-40 55L60 45V-60h80V45zm15-150c-45 5-45 65 0 70-20-25-20-50 0-70z',
          },
        },
      };
    },
  },
  'Key Terrain': {
    type: 'Graphic Control Measure',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'M50 40a50 50 0 10100 0 50 50 0 10-100 0',
            stroke: 'purple',
            strokeWidth: 6,
          },
          text_1: {
            symbolText: 'K',
            x: '100',
            y: '50',
            fontSize: '30',
            textAnchor: 'middle',
            fontWeight: 'bold',
          },
        },
      };
    },
  },
  Medical: {
    type: 'Sustainment - Medical',
    affiliation: {
      friendly: {
        path_1: {
          d: 'M100,50L100,150M25,100L175,100',
        },
      },
      get friendlyTemplated() {
        return this.friendly;
      },
      hostile: {
        path_1: {
          d: 'M100,28L100,172M28,100L172,100',
        },
      },
      get hostileTemplated() {
        return this.hostile;
      },
      unknown: {
        path_1: {
          d: 'M100,30.75L100,169.25M30.75,100L169.25,100',
        },
      },
      get pending() {
        return this.unknown;
      },
      neutral: {
        path_1: {
          d: 'M100,45L100,155M45,100L155,100',
        },
      },
    },
  },
  'Tented Camp': {
    type: 'Installation - Tents',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          path_1: {
            d: 'm 65,124.4 10,-37 25,-10 25,10 10,37 z',
          },
        },
      };
    },
  },
  'Special Troops': {
    type: 'Land Unit',
    get affiliation() {
      return {
        [resolveAffiliation()]: {
          text: {
            symbolText: 'ST',
            x: '100',
            y: '115',
            textAnchor: 'middle',
            fontSize: '42',
            fontWeight: 'bold',
          },
        },
      };
    },
  },
};

export default militarySymbolsObject;
