import '../styles/index.scss';
import { MDCSelect } from '@material/select';
import { MDCTextField, MDCTextFieldIcon } from '@material/textfield';
import { MDCRipple } from '@material/ripple';
import { MDCSwitch } from '@material/switch';
import { MDCTopAppBar } from '@material/top-app-bar';
import { MDCMenuSurface } from '@material/menu-surface';
// import Fuse from 'fuse.js';
import militarySymbolsObject from './symbolObjects/militarySymbols';
import mod1Object from './symbolObjects/mod1';
import mod2Object from './symbolObjects/mod2';
import commandPostObject from './symbolObjects/commandPost';
import MilSym from './app';
import pushbar from './ui/pushbar';
import {
  Resizer, bounceInAnimation, debounce, setSelectMenuTextContent,
} from './ui/helperFunctions';
import {
  map, generateGZDGrids, generate100kGrids, generate1000meterGrids,
} from './map/map';
// This will make all the images in your folder available to webpack
require.context('../img', true, /^\.\//);

//! Hovering over the Field Artillery popular symbol will engage the horizontal scroll bar on the Pushbar
// *********************************************************************************** //
// * Initialize Various Material Design Components                                   * //
// *********************************************************************************** //
// MDC - Top App Bar component
// eslint-disable-next-line no-unused-vars
const topAppBar = new MDCTopAppBar(document.querySelector('.mdc-top-app-bar'));
// MDC - Menu Surface component - The "Open Menu" button on the Top App Bar
const menuSurface = new MDCMenuSurface(document.querySelector('.mdc-menu-surface.ms2'));
const menuSurfaceButton = new MDCRipple(document.querySelector('.mdc-button.menu-surface-button.openMenuButton'));
// MDC - Button component - Upgrade your account to premium
const upgradeToPremium = new MDCRipple(document.querySelector('.mdc-button.upgradeButton'));
// MDC - Button component - Toggles the Pushbar opened or closed
const toggleSidebarButton = new MDCRipple(document.querySelector('.mdc-top-app-bar__navigation-icon'));
// MDC - Button Component - Download Symbol Buttons
const dlSymbolAsSVG = new MDCRipple(document.querySelector('.mdc-button.dlSymbolAsSVG'));
const dlSymbolAsPNG = new MDCRipple(document.querySelector('.mdc-button.dlSymbolAsPNG'));
// MDC - Select Menu component - Contains symbols, affiliation, unit sizes and modifiers
const selectSymbol = new MDCSelect(document.querySelector('.symbol-select'));
const selectAffiliation = new MDCSelect(document.querySelector('.affiliation-select'));
const selectUnitSize = new MDCSelect(document.querySelector('.unit-size-select'));
const selectMod1 = new MDCSelect(document.querySelector('.mod1-select'));
const selectMod2 = new MDCSelect(document.querySelector('.mod2-select'));
// MDC - Text Field component - User input for symbols
const uniqueDesignationField = new MDCTextField(document.querySelector('.uniqueDesignation'));
const uniqueDesignationIcon = new MDCRipple(document.querySelector('.mdc-button.uniqueDesignationDeleteIcon'));
const deleteUniqueDesignationButton = new MDCTextFieldIcon(uniqueDesignationIcon.root_);
// MDC - Text Field component - User input for symbols
const higherFormationField = new MDCTextField(document.querySelector('.higherFormation'));
const higherFormationIcon = new MDCRipple(document.querySelector('.mdc-button.higherFormationDeleteIcon'));
const deleteHigherFormationButton = new MDCTextFieldIcon(higherFormationIcon.root_);
// MDC - Switch component - Toggles 4 states, none, reinforced, reduced or reinforced and reduced
const reinforcedSwitch = new MDCSwitch(document.querySelector('.mdc-switch.reinforcedSwitch'));
const reducedSwitch = new MDCSwitch(document.querySelector('.mdc-switch.reducedSwitch'));
// MDC - Switch component - Toggles flying outline on specific symbols
const flyingSwitch = new MDCSwitch(document.querySelector('.mdc-switch.flightSwitch'));
// MDC - Switch component - Toggles activity indicator on symbols
const activitySwitch = new MDCSwitch(document.querySelector('.mdc-switch.activitySwitch'));
// MDC - Switch component - Toggles installation indicator on symbols
const installationSwitch = new MDCSwitch(document.querySelector('.mdc-switch.installationSwitch'));
// MDC - Switch component - Toggles Task Force Amplifier on symbols
const taskForceSwitch = new MDCSwitch(document.querySelector('.mdc-switch.taskForceSwitch'));
// MDC - Select Menu component - Contains various Command Post symbols
const selectCommandPost = new MDCSelect(document.querySelector('.commandpost-select'));
// MDC - Menu Surface component - The "Toggle Grid Overlays" button in the bottom app bar
const menuSurfaceToggleGrids = new MDCMenuSurface(document.querySelector('.mdc-menu-surface.ms1'));
const menuSurfaceToggleGridsButton = new MDCRipple(document.querySelector('.mdc-button.ms1'));
// MDC - Switch component - Toggle switches for the MGRS grid overlay
const gzdGridsSwitch = new MDCSwitch(document.querySelector('.mdc-switch.gzdGridsSwitch'));
const gzdLabelsSwitch = new MDCSwitch(document.querySelector('.mdc-switch.gzdLabelsSwitch'));
const labels100KSwitch = new MDCSwitch(document.querySelector('.mdc-switch.labels100KSwitch'));
const grids100KSwitch = new MDCSwitch(document.querySelector('.mdc-switch.grids100KSwitch'));
const labels1000MSwitch = new MDCSwitch(document.querySelector('.mdc-switch.labels1000MSwitch'));
const grids1000MSwitch = new MDCSwitch(document.querySelector('.mdc-switch.grids1000MSwitch'));
// Initial military symbol on page load
// eslint-disable-next-line import/no-mutable-exports
const MainMS = new MilSym('.newSVG', 'Default Land Unit', 'friendly', 'none', 'None', 'None', undefined, undefined, undefined, false, false, false, false, 'None');

function readCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// *********************************************************************************** //
// * Top App Bar - Menu Surface - User Login, Upgrade, Create Account, Blog          * //
// *********************************************************************************** //
const learnMore = document.querySelector('ul.userAccountList > .userAccount-LearnMore');
const userAccountLogin = document.querySelector('ul.userAccountList > .userAccount-login');
const userAccountUpgrade = document.querySelector('ul.userAccountList > .userAccount-upgrade');
const userAccountCreate = document.querySelector('ul.userAccountList > .userAccount-create');
const blog = document.querySelector('ul.userAccountList > .userAccount-blog');
const quiz = document.querySelector('ul.userAccountList > .userAccount-quiz');

learnMore.addEventListener('click', () => {
  if (process.env.NODE_ENV === 'development') {
    window.location.href = 'http://localhost:3030';
    return;
  }
  window.location.href = '/';
});

userAccountLogin.addEventListener('click', () => {
  if (process.env.NODE_ENV === 'development') {
    window.location.href = 'http://localhost:3000/auth/sign-in';
    return;
  }
  window.location.href = '/auth/sign-in';
});

userAccountUpgrade.addEventListener('click', () => {
  if (process.env.NODE_ENV === 'development') {
    window.location.href = 'http://localhost:3000/subscription/purchase';
    return;
  }
  window.location.href = '/subscription/purchase';
});

userAccountCreate.addEventListener('click', () => {
  // Development:
  if (process.env.NODE_ENV === 'development') {
    if (readCookie('mgrs-mapper-signed-in').valueOf() === 'true') {
      window.location.href = 'http://localhost:3000/account/manage';
      return;
    }
    window.location.href = 'http://localhost:3000/account/create';
    return;
  }
  // Production:
  // The user is a free account and he is signed in, go to account management page
  if (readCookie('mgrs-mapper-signed-in').valueOf() === 'true') {
    window.location.href = '/account/manage';
    return;
  }
  // Otherwise, the user is a free account and is not signed in, go to account create page
  window.location.href = '/account/create';
});

blog.addEventListener('click', () => {
  if (process.env.NODE_ENV === 'development') {
    window.location.href = 'http://localhost:3040/blog';
    return;
  }
  window.location.href = '/blog';
});

quiz.addEventListener('click', () => {
  if (process.env.NODE_ENV === 'development') {
    window.location.href = 'http://localhost:3050/quiz';
    return;
  }
  window.location.href = '/quiz';
});

// *********************************************************************************** //
// * Top App Bar                                                                     * //
// *********************************************************************************** //
menuSurfaceButton.listen('click', () => {
  if (menuSurface.isOpen()) {
    menuSurface.close();
    menuSurfaceButton.root_.innerText = 'OPEN MENU';
  } else {
    menuSurface.open();
    menuSurfaceButton.root_.innerText = 'CLOSE MENU';
    if (readCookie('mgrs-mapper-signed-in').valueOf() === 'true') {
      // Free user account signed in
      userAccountLogin.style.display = 'none';
      userAccountCreate.lastElementChild.innerText = 'Manage Account';
    } else {
      // No free user account signed in
      userAccountLogin.style.display = 'flex';
      userAccountCreate.lastElementChild.innerText = 'Create Account';
    }
  }

  menuSurface.setAbsolutePosition(0, 45);
  menuSurface.root_.classList.add('mdc-elevation-transition');
  menuSurface.root_.classList.add('mdc-elevation-z24');
});

toggleSidebarButton.unbounded = true;
toggleSidebarButton.listen('click', () => {
  if (pushbar.opened) {
    // If the pushbar is opened, close it and replace the menu_open icon with the regular menu icon
    toggleSidebarButton.root_.innerText = 'menu';
    pushbar.close();
  } else {
    toggleSidebarButton.root_.innerText = 'menu_open';
    pushbar.open('rightPushbar');
  }
});

menuSurface.listen('MDCMenuSurface:closed', () => {
  menuSurfaceButton.root_.innerText = 'OPEN MENU';
});

// *********************************************************************************** //
// * Top App Bar - Upgrade Account to Premium Button                                 * //
// *********************************************************************************** //
upgradeToPremium.listen('click', () => {
  if (process.env.NODE_ENV === 'development') {
    window.location.href = 'http://localhost:3000/subscription/purchase';
    return;
  }
  window.location.href = '/subscription/purchase';
});


// *********************************************************************************** //
// * Top App Bar - Menu Surface - Download Symbol Buttons                            * //
// *********************************************************************************** //
// Download the symbol as SVG
dlSymbolAsSVG.listen('click', () => {
  const symbol = document.querySelector('.newSVG > svg');
  // Animate the symbol out then in
  symbol.classList.add('scaleOutThenScaleIn');
  // Grab the original width, height, and viewBox settings from the downloaded SVG
  const originalViewBox = symbol.getAttribute('viewBox');
  const originalWidth = symbol.getAttribute('width');
  const originalHeight = symbol.getAttribute('height');
  symbol.setAttributeNS(null, 'preserveAspectRatio', 'xMidYMid');
  // Blow the image up by 3x
  symbol.setAttributeNS(null, 'width', `${symbol.getAttribute('width') * 3}`);
  symbol.setAttributeNS(null, 'height', `${symbol.getAttribute('height') * 3}`);
  // Add the new viewBox string, this is necessary IOT get the SVG file to center properly
  symbol.setAttributeNS(null, 'viewBox', `${symbol.getBBox().x - 4} ${symbol.getBBox().y - 4} ${symbol.getBBox().width + 8} ${symbol.getBBox().height + 8}`);
  // Remove spaces in symbol name
  const symbolName = JSON.parse(symbol.dataset.symbolInfo).Symbol.replace(/\s/g, '-');
  symbol.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  const svgData = symbol.outerHTML;
  const preface = '<?xml version="1.0" standalone="no"?>\r\n';
  const svgBlob = new Blob([preface, svgData], { type: 'image/svg+xml;charset=utf-8' });
  const svgUrl = URL.createObjectURL(svgBlob);
  const downloadLink = document.createElement('a');
  downloadLink.href = svgUrl;
  downloadLink.download = `${symbolName}_MGRS-Mapper.svg`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  // Now since the file is done downloading, reapply the original width, height and viewBox
  symbol.setAttributeNS(null, 'viewBox', `${originalViewBox}`);
  symbol.setAttributeNS(null, 'width', originalWidth);
  symbol.setAttributeNS(null, 'height', originalHeight);
  symbol.addEventListener('animationend', () => {
    // when the animation has ended, remove the class
    symbol.classList.remove('scaleOutThenScaleIn');
  }, { once: true });
});

// Download the symbol as PNG
dlSymbolAsPNG.listen('click', () => {
  const symbol = document.querySelector('.newSVG > svg');
  // When clicked, scale the symbol out then scale it back in
  symbol.classList.add('scaleOutThenScaleIn');
  // Save the original viewBox string so we can add it back on later
  const originalViewBox = symbol.getAttribute('viewBox');
  symbol.setAttributeNS(null, 'preserveAspectRatio', 'xMidYMid');
  // Add the new viewBox string, this is necessary IOT get the PNG file to center properly in the canvas
  symbol.setAttributeNS(null, 'viewBox', `${symbol.getBBox().x - 4} ${symbol.getBBox().y - 4} ${symbol.getBBox().width + 8} ${symbol.getBBox().height + 8}`);
  const symbolName = JSON.parse(symbol.dataset.symbolInfo).Symbol.replace(/\s/g, '-');
  const svgData = new XMLSerializer().serializeToString(symbol);
  const canvas = document.createElement('canvas');
  const svgSize = symbol.getBBox();
  // Now blow the canvas size up 3x
  canvas.width = svgSize.width * 3;
  canvas.height = svgSize.height * 3;
  const ctx = canvas.getContext('2d');
  const img = document.createElement('img');
  img.setAttribute('src', `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgData)))}`);
  img.onload = function () {
    // This voodoo code will horizontally and vertically center the image in the canvas
    const hRatio = canvas.width / img.width;
    const vRatio = canvas.height / img.height;
    const ratio = Math.max(hRatio, vRatio);
    const centerShiftX = (canvas.width - img.width * ratio) / 2;
    const centerShiftY = (canvas.height - img.height * ratio) / 2;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0, img.width, img.height, centerShiftX, centerShiftY, img.width * ratio, img.height * ratio);
    // Now create the link to automatically download
    const canvasData = canvas.toDataURL('image/png', 1);
    const downloadLink = document.createElement('a');
    downloadLink.href = canvasData;
    downloadLink.download = `${symbolName}_MGRS-Mapper.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    // Once the image is downloaded we can reapply the old viewBox data to the symbol
    symbol.setAttributeNS(null, 'viewBox', `${originalViewBox}`);
    symbol.addEventListener('animationend', () => {
      // when the animation has ended, remove the class
      symbol.classList.remove('scaleOutThenScaleIn');
    }, { once: true });
  };
});


// *********************************************************************************** //
// * Helper Functions                                                                * //
// *********************************************************************************** //
// * Add Symbols and Modifiers to the Dropdown lists * //
// ex- addSymbolsAndModsToList(mod1Object, 'mod1', selectMod1);
// obj = the object to iterate over (ex- mod1Object)
// abv = the abbreviation of the object to match the HTML select lists
// menu = the MDCSelect menu const in mdcComponents.js
// https://stackoverflow.com/questions/40185880/making-a-promise-work-inside-a-javascript-switch-case
async function addSymbolsAndModsToList(obj, abv, menu = null) {
  const promises = Object.keys(obj).forEach(async (key) => {
    const mdcList = document.querySelector(`.mdc-list.${abv}-list`);
    const newli = document.createElement('li');
    const modTypeInfo = document.createElement('em');
    modTypeInfo.setAttributeNS(null, 'class', `${abv}-type-info symbolTypeGrid mdc-typography--overline`);
    // Add the type of the Modifier in the drop down box
    modTypeInfo.textContent = obj[key].type;
    newli.setAttributeNS(null, 'class', 'mdc-list-item listGridParent');
    newli.setAttributeNS(null, 'data-value', key);
    await newli.insertAdjacentHTML('beforeend', `<span class="mdc-typography--headline6 symbolDescriptionGrid">${key}</span>`);
    newli.prepend(modTypeInfo);
    mdcList.append(newli);
    const figureElement = document.createElement('figure');
    figureElement.setAttributeNS(null, 'class', `${abv}Figure symbolFigureGrid`);
    // add the symbol key to the data-attr so they can match up with the list item
    figureElement.setAttributeNS(null, `data-${abv}-name`, `${key}`);
    newli.prepend(figureElement);
    // This will add the Symbols and Modifiers to the dropdown list
    switch (abv) {
      case 'mod1': {
        // All this does is remove the ESLint error for “Do not use 'new' for side effects”
        const mod1Promise = await new MilSym(`.mod1Figure[data-mod1-name="${key}"]`, `${selectSymbol.value}`, `${selectAffiliation.value}`, undefined, `${key}`);
        //! Fix 15JUN20: This will remove the affiliation outline on the Mod 1 symbols in the drop down list
        document.querySelector(`.mod1Figure[data-mod1-name="${key}"] > svg > g.outline`).remove();
        return mod1Promise;
      }
      case 'mod2': {
        const mod2Promise = await new MilSym(`.mod2Figure[data-mod2-name="${key}"]`, `${selectSymbol.value}`, `${selectAffiliation.value}`, undefined, undefined, `${key}`);
        //! Fix 15JUN20: This will remove the affiliation outline on the Mod 2 symbols in the drop down list
        document.querySelector(`.mod2Figure[data-mod2-name="${key}"] > svg > g.outline`).remove();
        return mod2Promise;
      }
      case 'commandpost': {
        // Set the default command post value to "None" on page load
        selectCommandPost.value = 'None';
        // Since we do not want to strip the outline of the command post, return this value
        const cpPromise = await new MilSym(`.commandpostFigure[data-commandpost-name="${key}"]`, `${selectSymbol.value}`, `${selectAffiliation.value}`, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, `${key}`, undefined);
        return cpPromise;
      }
      case 'symbol': {
        // Set the selected symbol to "Default Land Unit" on page load
        // Setting floatLabel(true) and setEnhancedSelectedIndex_(0) will avoid the symbol animations from running again.
        // For instance if you had 'selectSymbol.foundation_.setSelectedIndex(0);' the function to remove the animateSymbol class would run x times
        // x = the number of elements in the symbolSelect dropdown.
        // This actually might be a better way of doing things that just using "selectSymbol.foundation_.setSelectedIndex(0);"
        selectSymbol.foundation_.adapter_.floatLabel(true);
        selectSymbol.setEnhancedSelectedIndex_(0);
        // Returning 'symbol' since we need to keep the symbol affiliation outlines
        const symbolPromise = await new MilSym(`.symbolFigure[data-symbol-name="${key}"]`, `${key}`, `${selectAffiliation.value}`, undefined);
        return symbolPromise;
      }
      default:
        break;
    }
  });

  // Exclude the command post from being parsed
  if (menu !== null && abv !== 'commandpost') {
    // This will remove the affiliation containers on the Modifier elements in the dropdown
    const removeOutlineFromModifiers = await menu.menu_.items.map(async (element) => {
      // This targets the Modifier element (eg- the moon symbol for "foraging")
      const modElement = element.querySelectorAll('li figure svg g.outline path')[0];
      // This targets the SVG container for each Modifier element
      const modSVGContainer = modElement.parentElement.parentElement;
      // Set the affiliation outline background color to transparent, otherwise this will show a default land unit
      modElement.setAttributeNS(null, 'fill', 'transparent');
      // Set the affiliation outline stroke to 0
      modElement.setAttributeNS(null, 'stroke-width', '0');
      // Set the affiliation outline path to nothing
      modElement.setAttributeNS(null, 'd', '');
      // Scale the Modifier element down in the select box so they don't clip
      modSVGContainer.style.transform = 'scale(0.75)';
      // Set the selected index to the first item (usually this is "Default/None")
      menu.foundation_.setSelectedIndex(0);
    });
    const data = await Promise.all([promises, removeOutlineFromModifiers]);
    return data;
  }
}

//* Disabled selected inputs * //
function DisableInputs({
  affiliation = false,
  size = false,
  mod1 = false,
  mod2 = false,
  unique = false,
  higher = false,
  reinforced = false,
  reduced = false,
  activity = false,
  installation = false,
  taskForce = false,
  commandPost = false,
}) {
  if (affiliation) {
    selectAffiliation.disabled = true;
  } else {
    selectAffiliation.disabled = false;
  }

  if (size) {
    selectUnitSize.disabled = true;
    selectUnitSize.setEnhancedSelectedIndex_(0);
    selectUnitSize.root_.childNodes[5].innerText = 'None';
  } else {
    selectUnitSize.disabled = false;
  }

  if (mod1) {
    selectMod1.disabled = true;
    // using "selectMod1.selectedIndex = 0;" causes a change event which triggers placeSymbol()
    // setEnhancedSelectedIndex_(0) will "silently" change the select box index
    selectMod1.setEnhancedSelectedIndex_(0);
    // Since the index was "silently" changed, we need to reset the text
    selectMod1.targetElement_.textContent = 'None';
  } else {
    selectMod1.disabled = false;
  }

  if (mod2) {
    selectMod2.disabled = true;
    selectMod2.setEnhancedSelectedIndex_(0);
    selectMod2.targetElement_.textContent = 'None';
  } else {
    selectMod2.disabled = false;
  }

  if (unique) {
    uniqueDesignationField.disabled = true;
    uniqueDesignationField.value = '';
    deleteUniqueDesignationButton.root_.style.display = 'none';
  } else {
    uniqueDesignationField.disabled = false;
  }

  if (higher) {
    higherFormationField.disabled = true;
    higherFormationField.value = '';
    deleteHigherFormationButton.root_.style.display = 'none';
  } else {
    higherFormationField.disabled = false;
  }

  if (reinforced) {
    reinforcedSwitch.disabled = true;
    reinforcedSwitch.checked = false;
  } else {
    reinforcedSwitch.disabled = false;
  }

  if (reduced) {
    reducedSwitch.disabled = true;
    reducedSwitch.checked = false;
  } else {
    reducedSwitch.disabled = false;
  }

  if (activity) {
    activitySwitch.disabled = true;
    activitySwitch.checked = false;
  } else {
    activitySwitch.disabled = false;
  }

  if (installation) {
    installationSwitch.disabled = true;
    installationSwitch.checked = false;
  } else {
    installationSwitch.disabled = false;
  }

  if (taskForce) {
    taskForceSwitch.disabled = true;
    taskForceSwitch.checked = false;
  } else {
    taskForceSwitch.disabled = false;
  }

  if (commandPost) {
    selectCommandPost.disabled = true;
    selectCommandPost.setEnhancedSelectedIndex_(0);
    selectCommandPost.targetElement_.textContent = 'None';
  } else {
    selectCommandPost.disabled = false;
  }
}

// *********************************************************************************** //
// * Unique Unit Designation Field, Higher Unit Formation Field, MGRS Coords Field   * //
// *********************************************************************************** //
// This removes the unique/higher formation text from the symbol and text field
const clearDesignationFields = (event) => {
  switch (event.target.parentElement.dataset.value) {
    case 'uniqueDesignationField':
      uniqueDesignationField.value = '';
      deleteUniqueDesignationButton.root_.style.display = 'none';
      document.querySelector('g.uniqueUnitDesignation').textContent = '';
      MainMS.uniqueDesignation = '';
      break;
    case 'higherFormationField':
      higherFormationField.value = '';
      deleteHigherFormationButton.root_.style.display = 'none';
      document.querySelector('g.higherUnitFormation').textContent = '';
      MainMS.higherFormation = '';
      break;
    default:
      break;
  }
};

// This adds the unique/higher formation text on the symbol
const inputDesignationFields = debounce(async () => {
  if (uniqueDesignationField.input_.value !== '') {
    // Show the trash icon when there is any text in the search field
    deleteUniqueDesignationButton.root_.style.display = 'initial';
    deleteUniqueDesignationButton.root_.style.right = '0';
    deleteUniqueDesignationButton.root_.style.position = 'fixed';
    deleteUniqueDesignationButton.root_.style.top = '10px';
    // Setting z-index on trash icon makes it clickable
    deleteUniqueDesignationButton.root_.style.zIndex = '10';
    // * Directly edit the MainMS class instance instead of creating a whole new class * //
    MainMS.uniqueDesignation = uniqueDesignationField.value;
    MainMS.higherFormation = higherFormationField.value;
  } else {
    MainMS.uniqueDesignation = undefined;
    deleteUniqueDesignationButton.root_.style.display = 'none';
  }
  if (higherFormationField.input_.value !== '') {
    // Show the trash icon when there is any text in the search field
    deleteHigherFormationButton.root_.style.display = 'initial';
    deleteHigherFormationButton.root_.style.right = '0';
    deleteHigherFormationButton.root_.style.position = 'fixed';
    deleteHigherFormationButton.root_.style.top = '10px';
    // Setting z-index on trash icon makes it clickable
    deleteHigherFormationButton.root_.style.zIndex = '10';
    MainMS.uniqueDesignation = uniqueDesignationField.value;
    MainMS.higherFormation = higherFormationField.value;
  } else {
    MainMS.higherFormation = undefined;
    deleteHigherFormationButton.root_.style.display = 'none';
  }
  // Once all the values are set, run the placeSymbol function
  MainMS.placeSymbol();
}, 200);


[uniqueDesignationField, higherFormationField].forEach((key) => {
  key.input_.addEventListener('input', inputDesignationFields);
});


[deleteHigherFormationButton, deleteUniqueDesignationButton].forEach((key) => {
  key.root_.addEventListener('click', clearDesignationFields);
});


// *********************************************************************************** //
// * Reinforced and Reduced Switches                                                 * //
// *********************************************************************************** //
class RRSwitches {
  constructor() {
    this.reinforced = reinforcedSwitch.checked;
    this.reduced = reducedSwitch.checked;
    this.reinforcedAndReduced = this.reinforced && this.reduced;
    this.value = '';
    return this.checkSwitches();
  }

  checkSwitches() {
    switch (true) {
      case this.reinforcedAndReduced:
        reducedSwitch.root_.dataset.value = '±';
        reinforcedSwitch.root_.dataset.value = '';
        MainMS.reinforcedReduced = '±';
        MainMS.placeSymbol();
        this.value = '±';
        bounceInAnimation('g.reinforcedReduced');
        break;
      case this.reinforced:
        reinforcedSwitch.root_.dataset.value = '+';
        reducedSwitch.root_.dataset.value = '';
        MainMS.reinforcedReduced = '+';
        MainMS.placeSymbol();
        this.value = '+';
        bounceInAnimation('g.reinforcedReduced');
        break;
      case this.reduced:
        reducedSwitch.root_.dataset.value = '–';
        reinforcedSwitch.root_.dataset.value = '';
        MainMS.reinforcedReduced = '–';
        MainMS.placeSymbol();
        this.value = '–';
        bounceInAnimation('g.reinforcedReduced');
        break;
      default:
        reducedSwitch.root_.dataset.value = '';
        reinforcedSwitch.root_.dataset.value = '';
        // Check if the MainMS variable is in the global window. If not wait 30 ms
        if (Object.prototype.hasOwnProperty.call(window, 'MainMS')) {
          MainMS.reinforcedReduced = '';
          MainMS.placeSymbol();
        } else {
          setTimeout(() => {
            MainMS.reinforcedReduced = '';
            MainMS.placeSymbol();
          }, 30);
        }
        this.value = '';
        break;
    }
  }
}

const reinforcedReducedValue = () => new RRSwitches().value;

[reducedSwitch, reinforcedSwitch].forEach((key) => {
  key.listen('change', reinforcedReducedValue);
});


// *********************************************************************************** //
// * Flying Switch                                                                   * //
// *********************************************************************************** //
function enableFlyingOutline() {
  if (flyingSwitch.checked) {
    MainMS.flying = true;
    DisableInputs({
      affiliation: false,
      size: true,
      mod1: false,
      mod2: false,
      unique: true,
      higher: true,
      reinforced: true,
      reduced: true,
      activity: true,
      installation: true,
      taskForce: true,
      commandPost: true,
    });
    MainMS.placeSymbol();
  } else if (Object.prototype.hasOwnProperty.call(window, 'MainMS')) {
    MainMS.flying = false;
    //! Fix 15JUN20: When a flight capable symbol has flying enabled and then turned off, the rest of the inputs are kept disabled. Otherwise the user would be able to add installation/reduced/task force/etc
    if (MainMS.type === 'Equipment') {
      DisableInputs({
        affiliation: false,
        size: true,
        mod1: false,
        mod2: false,
        unique: true,
        higher: true,
        reinforced: true,
        reduced: true,
        activity: true,
        installation: true,
        taskForce: true,
        commandPost: true,
      });
      MainMS.placeSymbol();
    } else {
      DisableInputs({});
      MainMS.placeSymbol();
    }
  } else {
    setTimeout(() => {
      MainMS.flying = false;
      console.log('two');
      DisableInputs({});
      MainMS.placeSymbol();
    }, 30);
  }
}

flyingSwitch.listen('change', enableFlyingOutline);


// *********************************************************************************** //
// * Activity Switch                                                                 * //
// *********************************************************************************** //
function enableActivity() {
  if (activitySwitch.checked) {
    MainMS.activity = true;
    MainMS.placeSymbol();
    bounceInAnimation('g.activity'); // This looks kinda funky
    return true;
  }
  MainMS.activity = false;
  MainMS.placeSymbol();
  return false;
}

activitySwitch.listen('change', enableActivity);


// *********************************************************************************** //
// * Installation Switch                                                             * //
// *********************************************************************************** //
function enableInstallation() {
  if (installationSwitch.checked) {
    MainMS.installation = true;
    MainMS.placeSymbol();
    bounceInAnimation('g.installation');
    return true;
  }
  MainMS.installation = false;
  MainMS.placeSymbol();
  return false;
}

installationSwitch.listen('change', enableInstallation);


// *********************************************************************************** //
// * Task Force Switch                                                               * //
// *********************************************************************************** //
async function enableTaskForce() {
  if (taskForceSwitch.checked) {
    MainMS.taskForce = true;
    MainMS.placeSymbol();
    bounceInAnimation('g.taskforce');
    return true;
  }
  MainMS.taskForce = false;
  MainMS.placeSymbol();
  return false;
}

taskForceSwitch.listen('change', enableTaskForce);


// *********************************************************************************** //
// * Bottom App Bar - Toggle Grid Overlays                                           * //
// *********************************************************************************** //
menuSurfaceToggleGridsButton.listen('click', () => {
  menuSurfaceToggleGrids.isOpen() ? menuSurfaceToggleGrids.close() : menuSurfaceToggleGrids.open();
  // menuSurfaceToggleGrids.setAbsolutePosition(0, -50);
  // This will butt the menu surface up to the top of the button
  setTimeout(() => {
    menuSurfaceToggleGrids.root_.style.bottom = '43px';
  }, 30);
});

// Toggle GZD labels
gzdLabelsSwitch.listen('change', (event) => {
  const checkbox = event.target;
  if (checkbox.checked) {
    generateGZDGrids.showLabels();
  } else {
    generateGZDGrids.hideLabels();
  }
});

// Toggle GZD grids
gzdGridsSwitch.listen('change', (event) => {
  const checkbox = event.target;
  if (checkbox.checked) {
    generateGZDGrids.hideGrids();
  } else {
    generateGZDGrids.showGrids();
  }
});

// Toggle 100k labels
labels100KSwitch.listen('change', (event) => {
  const checkbox = event.target;
  if (checkbox.checked) {
    generate100kGrids.showLabels();
  } else {
    generate100kGrids.hideLabels();
  }
});

// Toggle 100k grids
grids100KSwitch.listen('change', (event) => {
  const checkbox = event.target;
  if (checkbox.checked) {
    generate100kGrids.showGrids();
  } else {
    generate100kGrids.hideGrids();
  }
});

// Toggle 1000m labels
labels1000MSwitch.listen('change', (event) => {
  const checkbox = event.target;
  if (checkbox.checked) {
    generate1000meterGrids.showLabels();
  } else {
    generate1000meterGrids.hideLabels();
  }
});

// Toggle 1000m grids
grids1000MSwitch.listen('change', (event) => {
  const checkbox = event.target;
  if (checkbox.checked) {
    generate1000meterGrids.showGrids();
  } else {
    generate1000meterGrids.hideGrids();
  }
});


// Automatically disabled switches that cannot be used at certain zoom levels
map.whenReady(() => {
  const switchValidator = () => {
    // 1000 meter grids - zoom level 12
    if (map.getZoom() < generate1000meterGrids.options.minZoom) {
      labels1000MSwitch.disabled = true;
      grids1000MSwitch.disabled = true;
    } else {
      generate1000meterGrids.options.showGrids ? grids1000MSwitch.checked = true : grids1000MSwitch.checked = false;
      generate1000meterGrids.options.showLabels ? labels1000MSwitch.checked = true : labels1000MSwitch.checked = false;
      labels1000MSwitch.disabled = false;
      grids1000MSwitch.disabled = false;
    }
    // 100k grids - zoom level 6
    if (map.getZoom() < generate100kGrids.options.minZoom) {
      labels100KSwitch.disabled = true;
      grids100KSwitch.disabled = true;
    } else {
      generate100kGrids.options.showGrids ? grids100KSwitch.checked = true : grids100KSwitch.checked = false;
      generate100kGrids.options.showLabels ? labels100KSwitch.checked = true : labels100KSwitch.checked = false;
      labels100KSwitch.disabled = false;
      grids100KSwitch.disabled = false;
    }
    // Special case - the 100K labels do not appear on my layer plugin at zoom level 6. This is to improve performance
    if (map.getZoom() === 6) {
      labels100KSwitch.checked = false;
      labels100KSwitch.disabled = true;
    }
    // GZD - zoom level 3
    if (map.getZoom() < generateGZDGrids.options.minZoom) {
      gzdLabelsSwitch.disabled = true;
      gzdGridsSwitch.disabled = true;
    } else {
      generateGZDGrids.options.showGrids ? gzdGridsSwitch.checked = true : gzdGridsSwitch.checked = false;
      generateGZDGrids.options.showLabels ? gzdLabelsSwitch.checked = true : gzdLabelsSwitch.checked = false;
      gzdLabelsSwitch.disabled = false;
      gzdGridsSwitch.disabled = false;
    }
  };
  map.on('zoomend', switchValidator);
  switchValidator();
});


// *********************************************************************************** //
// * Load the Symbols and Modifiers into the dropdowns on page load                  * //
// *********************************************************************************** //
addSymbolsAndModsToList(militarySymbolsObject, 'symbol');
addSymbolsAndModsToList(mod1Object, 'mod1', selectMod1);
addSymbolsAndModsToList(mod2Object, 'mod2', selectMod2);
addSymbolsAndModsToList(commandPostObject, 'commandpost', selectCommandPost);
setSelectMenuTextContent(selectSymbol, selectMod1, selectMod2, selectCommandPost);

window.MainMS = MainMS; //! MainMS is in the global scope so it can be reference and edited

// Hide the text field trash can buttons on page load
deleteUniqueDesignationButton.root_.style.display = 'none';
deleteHigherFormationButton.root_.style.display = 'none';


// *********************************************************************************** //
// * Most Popular, Symbols, Affiliation, Unit Size, Mod 1, Mod 2, Command Post       * //
// *********************************************************************************** //
// This will automatically center the tooltip on the Most Popular Symbols section
document.querySelectorAll('.tooltip').forEach((key) => {
  key.addEventListener('mouseover', () => {
    const container = key.offsetWidth;
    const tooltip = key.lastElementChild.offsetWidth;
    // Add 5 pixels to adjust for padding
    key.lastElementChild.style.left = `${(container / 2) - (tooltip / 2) + 5}px`;
    key.lastElementChild.style.boxShadow = '0px 0px 10px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 30px rgba(0, 0, 0, 0.3)';
  });
  key.addEventListener('click', async (event) => {
    // When a user clicks on a popular symbol, clear the search field to prevent errors
    // if (searchField.value !== '') {
    //   await clearSearchField();
    // }
    const elements = document.elementsFromPoint(event.clientX, event.clientY);
    const chosenTarget = elements.find((key2) => key2.matches('svg'));
    // This will trigger the selectSymbol event listener below
    selectSymbol.value = chosenTarget.dataset.symbol;
  });
});

selectSymbol.listen('MDCSelect:change', () => {
  const changeSymbols = new Promise((resolve, reject) => {
    setSelectMenuTextContent(selectSymbol);
    MainMS.symbol = selectSymbol.value;
    MainMS.placeSymbol();
    resolve(MainMS);
    reject(new Error('changeSymbols Promise Rejected'));
  });

  changeSymbols.then(() => {
    // Disable switches and inputs for Equipment and Graphic Control Measures
    switch (MainMS.type) {
      case 'Equipment':
        // Disable all except, symbol, affiliation, mod1, mod2, and flying
        // (note: flying is automatically disabled unless the symbol has a 'flightCapable: true' property)
        DisableInputs({
          affiliation: false,
          size: true,
          mod1: false,
          mod2: false,
          unique: true,
          higher: true,
          reinforced: true,
          reduced: true,
          activity: true,
          installation: true,
          taskForce: true,
          commandPost: true,
        });
        break;
      case 'Graphic Control Measure':
        flyingSwitch.disabled = true;
        flyingSwitch.checked = false;
        DisableInputs({
          affiliation: true,
          size: true,
          mod1: true,
          mod2: true,
          unique: true,
          higher: true,
          reinforced: true,
          reduced: true,
          activity: true,
          installation: true,
          taskForce: true,
          commandPost: true,
        });
        break;
      case 'Tactical Mission Task':
        flyingSwitch.disabled = true;
        flyingSwitch.checked = false;
        DisableInputs({
          affiliation: true,
          size: true,
          mod1: true,
          mod2: true,
          unique: true,
          higher: true,
          reinforced: true,
          reduced: true,
          activity: true,
          installation: true,
          taskForce: true,
          commandPost: true,
        });
        break;
      default:
        // Enable all inputs
        DisableInputs({});
        break;
    }

    const addAndRemoveSymbolPanelAnimation = () => {
      // Add the animateSymbol class the the symbol in the panel
      document.querySelector('.newSVG > svg').classList.add('animateSymbol');
      //! Is this too much voodoo for our holy purposes?
      //! This is supposed to prevent the symbol panel from flipping the fuck out when the user is hovered over the symbol as it drops down
      //! It's a minor bug but kinda annoying
      window.addEventListener('animationiteration', () => {
        if (document.querySelector('.newSVG').getAttribute('draggable')) {
          document.querySelector('.newSVG').setAttribute('draggable', 'false');
          document.querySelector('.newSVG > svg').classList.remove('animateSymbol');
        }
      }, { once: true });

      // When the animation ends, remove it from the symbol, otherwise it will keep animating when you mouse over
      window.addEventListener('animationend', () => {
        document.querySelector('.newSVG > svg').classList.remove('animateSymbol');
        // Set to once to automatically remove the event listener
      }, { once: true });
    };

    // Only animate the symbol when a new symbol is clicked. This prevents the animation occurring on every single keyup in search field
    // if (!searchField.input_.value) {
    addAndRemoveSymbolPanelAnimation();
    // }
  }, (error) => {
    console.log(error);
  });
});

selectAffiliation.listen('MDCSelect:change', () => {
  // This replaces camel case for things like "friendlyTemplated" into "Friendly / Templated"
  selectAffiliation.selectedText_.textContent = selectAffiliation.value.replace(/([A-Z])/g, ' / $1').replace(/^./, (str) => str.toUpperCase());
  MainMS.affiliation = selectAffiliation.value;
  MainMS.placeSymbol();
  // If a user changes unit affiliation, and the flying switch is checked, run this function to immediately change the outline
  if (flyingSwitch.checked) {
    enableFlyingOutline();
  }

  const pushbarDiv = document.querySelector('.pushbar');
  // Field Artillery
  const faOutline = pushbarDiv.querySelector('.mps_fa--Outline');
  // Mechanized Infantry
  const maiOutline = pushbarDiv.querySelector('.mps_mai--Outline');
  const maiDecorator = pushbarDiv.querySelector('.mps_mai--Decorator');
  // Armored Track Unit
  const atuOutline = pushbarDiv.querySelector('.mps_atu--Outline');
  // Infantry
  const inOutline = pushbarDiv.querySelector('.mps_in--Outline');
  const inDecorator = pushbarDiv.querySelector('.mps_in--Decorator');

  // When the user changes affiliation, change the affiliation outlines of the Frequently Used Symbols
  switch (selectAffiliation.value) {
    case 'friendly':
      // Field Artillery
      faOutline.setAttributeNS(null, 'd', 'M25,50 l150,0 0,100 -150,0 z');
      faOutline.setAttributeNS(null, 'fill', 'rgb(128,224,255)');
      faOutline.setAttributeNS(null, 'stroke-dasharray', '0');
      // Mechanized Infantry
      maiOutline.setAttributeNS(null, 'd', 'M25,50 l150,0 0,100 -150,0 z');
      maiOutline.setAttributeNS(null, 'fill', 'rgb(128,224,255)');
      maiOutline.setAttributeNS(null, 'stroke-dasharray', '0');
      maiDecorator.setAttributeNS(null, 'd', 'M25 50l150 100m-150 0L175 50');
      // Armored Track Unit
      atuOutline.setAttributeNS(null, 'd', 'M25,50 l150,0 0,100 -150,0 z');
      atuOutline.setAttributeNS(null, 'fill', 'rgb(128,224,255)');
      atuOutline.setAttributeNS(null, 'stroke-dasharray', '0');
      // Infantry
      inOutline.setAttributeNS(null, 'd', 'M25,50 l150,0 0,100 -150,0 z');
      inOutline.setAttributeNS(null, 'fill', 'rgb(128,224,255)');
      inOutline.setAttributeNS(null, 'stroke-dasharray', '0');
      inDecorator.setAttributeNS(null, 'd', 'M25,50 L175,150 M25,150 L175,50');
      break;
    case 'friendlyTemplated':
      // Field Artillery
      faOutline.setAttributeNS(null, 'd', 'M25,50 l150,0 0,100 -150,0 z');
      faOutline.setAttributeNS(null, 'fill', 'rgb(128,224,255)');
      faOutline.setAttributeNS(null, 'stroke-dasharray', '4,4');
      // Mechanized Infantry
      maiOutline.setAttributeNS(null, 'd', 'M25,50 l150,0 0,100 -150,0 z');
      maiOutline.setAttributeNS(null, 'fill', 'rgb(128,224,255)');
      maiOutline.setAttributeNS(null, 'stroke-dasharray', '4,4');
      maiDecorator.setAttributeNS(null, 'd', 'M25 50l150 100m-150 0L175 50');
      // Armored Track Unit
      atuOutline.setAttributeNS(null, 'd', 'M25,50 l150,0 0,100 -150,0 z');
      atuOutline.setAttributeNS(null, 'fill', 'rgb(128,224,255)');
      atuOutline.setAttributeNS(null, 'stroke-dasharray', '4,4');
      // Infantry
      inOutline.setAttributeNS(null, 'd', 'M25,50 l150,0 0,100 -150,0 z');
      inOutline.setAttributeNS(null, 'fill', 'rgb(128,224,255)');
      inOutline.setAttributeNS(null, 'stroke-dasharray', '4,4');
      inDecorator.setAttributeNS(null, 'd', 'M25,50 L175,150 M25,150 L175,50');
      break;
    case 'hostile':
      // Field Artillery
      faOutline.setAttributeNS(null, 'd', 'M 100,28 L172,100 100,172 28,100 100,28 Z');
      faOutline.setAttributeNS(null, 'fill', 'rgb(255,128,128)');
      faOutline.setAttributeNS(null, 'stroke-dasharray', '0');
      // Mechanized Infantry
      maiOutline.setAttributeNS(null, 'd', 'M 100,28 L172,100 100,172 28,100 100,28 Z');
      maiOutline.setAttributeNS(null, 'fill', 'rgb(255,128,128)');
      maiOutline.setAttributeNS(null, 'stroke-dasharray', '0');
      maiDecorator.setAttributeNS(null, 'd', 'M60,70L140,130M60,130L140,70');
      // Armored Track Unit
      atuOutline.setAttributeNS(null, 'd', 'M 100,28 L172,100 100,172 28,100 100,28 Z');
      atuOutline.setAttributeNS(null, 'fill', 'rgb(255,128,128)');
      atuOutline.setAttributeNS(null, 'stroke-dasharray', '0');
      // Infantry
      inOutline.setAttributeNS(null, 'd', 'M 100,28 L172,100 100,172 28,100 100,28 Z');
      inOutline.setAttributeNS(null, 'fill', 'rgb(255,128,128)');
      inOutline.setAttributeNS(null, 'stroke-dasharray', '0');
      inDecorator.setAttributeNS(null, 'd', 'M65,65 L137,137 M65, 137 L137, 65');
      break;
    case 'hostileTemplated':
      // Field Artillery
      faOutline.setAttributeNS(null, 'd', 'M 100,28 L172,100 100,172 28,100 100,28 Z');
      faOutline.setAttributeNS(null, 'fill', 'rgb(255,128,128)');
      faOutline.setAttributeNS(null, 'stroke-dasharray', '4,4');
      // Mechanized Infantry
      maiOutline.setAttributeNS(null, 'd', 'M 100,28 L172,100 100,172 28,100 100,28 Z');
      maiOutline.setAttributeNS(null, 'fill', 'rgb(255,128,128)');
      maiOutline.setAttributeNS(null, 'stroke-dasharray', '4,4');
      maiDecorator.setAttributeNS(null, 'd', 'M60,70L140,130M60,130L140,70');
      // Armored Track Unit
      atuOutline.setAttributeNS(null, 'd', 'M 100,28 L172,100 100,172 28,100 100,28 Z');
      atuOutline.setAttributeNS(null, 'fill', 'rgb(255,128,128)');
      atuOutline.setAttributeNS(null, 'stroke-dasharray', '4,4');
      // Infantry
      inOutline.setAttributeNS(null, 'd', 'M 100,28 L172,100 100,172 28,100 100,28 Z');
      inOutline.setAttributeNS(null, 'fill', 'rgb(255,128,128)');
      inOutline.setAttributeNS(null, 'stroke-dasharray', '4,4');
      inDecorator.setAttributeNS(null, 'd', 'M65,65 L137,137 M65, 137 L137, 65');
      break;
    case 'unknown':
      // Field Artillery
      faOutline.setAttributeNS(null, 'd', 'M63 63c0-43 74-43 74 0 43 0 43 74 0 74 0 43-74 43-74 0-43 0-43-74 0-74z');
      faOutline.setAttributeNS(null, 'fill', 'rgb(255,255,128)');
      faOutline.setAttributeNS(null, 'stroke-dasharray', '0');
      // Mechanized Infantry
      maiOutline.setAttributeNS(null, 'd', 'M63 63c0-43 74-43 74 0 43 0 43 74 0 74 0 43-74 43-74 0-43 0-43-74 0-74z');
      maiOutline.setAttributeNS(null, 'fill', 'rgb(255,255,128)');
      maiOutline.setAttributeNS(null, 'stroke-dasharray', '0');
      maiDecorator.setAttributeNS(null, 'd', 'M50,65L150,135M50,135L150,65');
      // Armored Track Unit
      atuOutline.setAttributeNS(null, 'd', 'M63 63c0-43 74-43 74 0 43 0 43 74 0 74 0 43-74 43-74 0-43 0-43-74 0-74z');
      atuOutline.setAttributeNS(null, 'fill', 'rgb(255,255,128)');
      atuOutline.setAttributeNS(null, 'stroke-dasharray', '0');
      // Infantry
      inOutline.setAttributeNS(null, 'd', 'M63 63c0-43 74-43 74 0 43 0 43 74 0 74 0 43-74 43-74 0-43 0-43-74 0-74z');
      inOutline.setAttributeNS(null, 'fill', 'rgb(255,255,128)');
      inOutline.setAttributeNS(null, 'stroke-dasharray', '0');
      inDecorator.setAttributeNS(null, 'd', 'M50,65L150,135M50,135L150,65');
      break;
    case 'pending':
      // Field Artillery
      faOutline.setAttributeNS(null, 'd', 'M63 63c0-43 74-43 74 0 43 0 43 74 0 74 0 43-74 43-74 0-43 0-43-74 0-74z');
      faOutline.setAttributeNS(null, 'fill', 'rgb(255,255,128)');
      faOutline.setAttributeNS(null, 'stroke-dasharray', '4,4');
      // Mechanized Infantry
      maiOutline.setAttributeNS(null, 'd', 'M63 63c0-43 74-43 74 0 43 0 43 74 0 74 0 43-74 43-74 0-43 0-43-74 0-74z');
      maiOutline.setAttributeNS(null, 'fill', 'rgb(255,255,128)');
      maiOutline.setAttributeNS(null, 'stroke-dasharray', '4,4');
      maiDecorator.setAttributeNS(null, 'd', 'M50,65L150,135M50,135L150,65');
      // Armored Track Unit
      atuOutline.setAttributeNS(null, 'd', 'M63 63c0-43 74-43 74 0 43 0 43 74 0 74 0 43-74 43-74 0-43 0-43-74 0-74z');
      atuOutline.setAttributeNS(null, 'fill', 'rgb(255,255,128)');
      atuOutline.setAttributeNS(null, 'stroke-dasharray', '4,4');
      // Infantry
      inOutline.setAttributeNS(null, 'd', 'M63 63c0-43 74-43 74 0 43 0 43 74 0 74 0 43-74 43-74 0-43 0-43-74 0-74z');
      inOutline.setAttributeNS(null, 'fill', 'rgb(255,255,128)');
      inOutline.setAttributeNS(null, 'stroke-dasharray', '4,4');
      inDecorator.setAttributeNS(null, 'd', 'M50,65L150,135M50,135L150,65');
      break;
    case 'neutral':
      // Field Artillery
      faOutline.setAttributeNS(null, 'd', 'M45,45 l110,0 0,110 -110,0 z');
      faOutline.setAttributeNS(null, 'fill', 'rgb(170, 255, 170)');
      faOutline.setAttributeNS(null, 'stroke-dasharray', '0');
      // Mechanized Infantry
      maiOutline.setAttributeNS(null, 'd', 'M45,45 l110,0 0,110 -110,0 z');
      maiOutline.setAttributeNS(null, 'fill', 'rgb(170, 255, 170)');
      maiOutline.setAttributeNS(null, 'stroke-dasharray', '0');
      maiDecorator.setAttributeNS(null, 'd', 'M45,45L155,155M45,155L155,45');
      // Armored Track Unit
      atuOutline.setAttributeNS(null, 'd', 'M45,45 l110,0 0,110 -110,0 z');
      atuOutline.setAttributeNS(null, 'fill', 'rgb(170, 255, 170)');
      atuOutline.setAttributeNS(null, 'stroke-dasharray', '0');
      // Infantry
      inOutline.setAttributeNS(null, 'd', 'M45,45 l110,0 0,110 -110,0 z');
      inOutline.setAttributeNS(null, 'fill', 'rgb(170, 255, 170)');
      inOutline.setAttributeNS(null, 'stroke-dasharray', '0');
      inDecorator.setAttributeNS(null, 'd', 'M45,45L155,155M45,155L155,45');
      break;
    default:
      break;
  }
});

selectUnitSize.listen('MDCSelect:change', () => {
  selectUnitSize.selectedText_.textContent = selectUnitSize.value.replace(/([A-Z])/g, ' / $1').replace(/^./, (str) => str.toUpperCase());
  MainMS.echelon = selectUnitSize.value;
  MainMS.placeSymbol();
  bounceInAnimation('g.echelon');
});

selectMod1.listen('MDCSelect:change', () => {
  bounceInAnimation('g.mod1');
  setSelectMenuTextContent(selectMod1);
  MainMS.mod1 = selectMod1.value;
  MainMS.placeSymbol();
});

selectMod2.listen('MDCSelect:change', () => {
  setSelectMenuTextContent(selectMod2);
  MainMS.mod2 = selectMod2.value;
  MainMS.placeSymbol();
  bounceInAnimation('g.mod2');
});

selectCommandPost.listen('MDCSelect:change', () => {
  setSelectMenuTextContent(selectCommandPost);
  MainMS.commandPost = selectCommandPost.value;
  MainMS.placeSymbol();
  bounceInAnimation('g.commandpost');
});

[selectUnitSize, selectMod1, selectMod2].forEach((key) => {
  key.listen('click', () => {
    // If any of these menus are open, then resize all the symbols
    if (selectUnitSize.isMenuOpen_) {
      Resizer('.unitSizeFigure svg', 93, 33);
    }
    if (selectMod1.isMenuOpen_) {
      Resizer('.mod1Figure svg');
    }
    if (selectMod2.isMenuOpen_) {
      Resizer('.mod2Figure svg');
    }
  });
});


// *********************************************************************************** //
// * Dynamically Adjust Affiliation and viewBox for symbols that are in the viewport * //
// *********************************************************************************** //
function Viewport() {
  this.x = window.pageXOffset;
  this.w = window.innerWidth;
  this.x2 = this.x + this.w - 1;
  this.y = window.pageYOffset;
  this.h = window.innerHeight;
  this.y2 = this.y + this.h - 1;
  return this;
}

const viewport = new Viewport();

function isVisible(element, vp) {
  // This checks if the element is in the viewport area
  const rect = element.getBoundingClientRect();
  const x = rect.left;
  const x2 = x + element.offsetWidth;
  const y = rect.top;
  const y2 = y + element.offsetHeight;
  return !(x >= vp.w || y >= vp.h || x2 < 0 || y2 < 0);
}

// This will prevent the symbols from being regenerated if the affiliation hasn't changed. Otherwise every time you open the symbolSelect it would run placeSymbol()
const oldAffiliationValue = [selectAffiliation.value];
selectSymbol.listen('click', () => {
  if (oldAffiliationValue[0] !== selectAffiliation.value) {
    // If the previous affiliation and the current affiliation are NOT equal, then pop the last value, push the new value
    oldAffiliationValue.pop();
    oldAffiliationValue.push(selectAffiliation.value);
    // When an affiliation is selected, change the outlines of visible symbols in the dropdown only if the selectSymbol menu is open though
    if (selectSymbol.isMenuOpen_) {
      selectSymbol.menu_.items.map((key) => {
        const type = JSON.parse(key.firstElementChild.firstElementChild.dataset.symbolInfo).Type;
        // Change outlines of symbols that are visible in the viewport and then only if they are NOT TMTs or GCMs
        if (isVisible(key, viewport)) {
          if (type !== 'Tactical Mission Task') {
            if (type !== 'Graphic Control Measure') {
              (() => new MilSym(`.symbolFigure[data-symbol-name="${key.dataset.value}"]`, `${key.dataset.value}`, `${selectAffiliation.value}`))();
            }
          }
        }
      });
    }
  }

  (async () => {
    if (await selectSymbol.isMenuOpen_) {
      Resizer('.symbolFigure svg');
    }
  })();

  if (flyingSwitch.checked) {
    flyingSwitch.disabled = false;
  }
});

// When the user is scrolling through the selectSymbol menu do the following:
// 1. Check if the symbols on the menu are in the viewport
// 2. Check if the symbol is NOT a Tactical Mission Task
// 3. Check if the symbol is NOT a Graphic Control measure
// 4. Run the MilSym class to change the affiliation
// 5. Run the Resizer class to resize the symbol so it fits in the list element
// Previously it was taking the selectSymbol menu ~1000ms to open up, with isVisible() it is now down to ~120ms
selectSymbol.menu_.listen('scroll', () => {
  selectSymbol.menu_.items.map((key) => {
    const type = JSON.parse(key.firstElementChild.firstElementChild.dataset.symbolInfo).Type;
    if (isVisible(key, viewport)) {
      if (type !== 'Tactical Mission Task') {
        if (type !== 'Graphic Control Measure') {
          (() => new MilSym(`.symbolFigure[data-symbol-name="${key.dataset.value}"]`, `${key.dataset.value}`, `${selectAffiliation.value}`))();
          Resizer(`.symbolFigure[data-symbol-name="${key.dataset.value}"] svg`);
        }
      }
    }
  });
});

const oldAffiliationValueCP = [selectAffiliation.value];
selectCommandPost.listen('click', () => {
  if (oldAffiliationValueCP[0] !== selectAffiliation.value) {
    // If the previous affiliation and the current affiliation are NOT equal, then pop the last value, push the new value
    oldAffiliationValueCP.pop();
    oldAffiliationValueCP.push(selectAffiliation.value);
    // Now create the new symbol outlines only if the selectCommandPost menu is open though
    if (selectCommandPost.isMenuOpen_) {
      selectCommandPost.menu_.items.map((key) => {
        (() => new MilSym(`.commandpostFigure[data-commandpost-name="${key.dataset.value}"]`, 'Default Land Unit', `${selectAffiliation.value}`, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, `${key.dataset.value}`))();
      });
    }
  }
  // If the previous affiliation and the current affiliation are equal, then do not change the symbol outlines, just resize them only if the menu is open
  if (selectCommandPost.isMenuOpen_) {
    Resizer('.commandpostFigure svg', 100, 100);
  }
});


// *********************************************************************************** //
// * Adjust Pushbar so it fits on all screen sizes                                   * //
// *********************************************************************************** //
// Add the pulsating prompt above the symbol in the symbol panel
document.querySelector('.newSVG').insertAdjacentHTML('beforebegin',
  `<span class="mdc-select-helper-text mdc-select-helper-text--persistent drag-and-drop-reminder">
      <i class="material-icons">format_shapes</i>
      Click and Drag the Symbol Onto the Map
    </span>`);

// Open the pushbar on page load
pushbar.open('rightPushbar');

const topAppBarHeight = topAppBar.getDefaultFoundation().topAppBarHeight_;
const mainContent = document.querySelector('main');
const footer = document.querySelector('.abbFooter');
const symbolSelectorsDiv = document.querySelector('.symbolSelectors');
const pushbarDiv = document.querySelector('.pushbar');
const newSVGDiv = document.querySelector('.newSVG');
const documentHeight = document.body.clientHeight;
const symbolPreview = document.querySelector('.symbolPreviewGrid');

mainContent.setAttribute('style', `padding-top: ${topAppBarHeight}px; height: ${document.body.clientHeight - topAppBarHeight - footer.clientHeight}px;`);
pushbarDiv.setAttribute('style', `padding-top: ${topAppBarHeight}px; height: ${document.body.clientHeight - topAppBarHeight - footer.clientHeight}px;`);
// ensure that the symbol preview box takes up no more than 22.79% of the screen
newSVGDiv.setAttribute('style', `height: ${document.body.clientHeight * 0.2279}px;`);
symbolSelectorsDiv.setAttribute('style', `padding-bottom: ${footer.clientHeight / 2}px; max-height: ${documentHeight - symbolPreview.clientHeight - footer.clientHeight - topAppBarHeight}px;`);


// *********************************************************************************** //
// * Special Tasks for Mobile Browsers                                               * //
// *********************************************************************************** //
// if mobile browser detected, change the drag and drop reminder text in the symbol panel
if (!matchMedia('(any-pointer:fine)').matches) {
  const ddr = document.querySelector('.drag-and-drop-reminder');
  ddr.innerHTML = `<i class="material-icons">format_shapes</i>
  Touch the Symbol to Add to the Map`;
}


// *********************************************************************************** //
// * Sort Symbol Object Lists Alphabetically                                         * //
// *********************************************************************************** //
// https://stackoverflow.com/questions/42722754/sorting-li-elements-alphabetically
function sortSymbolObjectList(ul) {
  const uli = document.querySelector(ul);

  Array.from(uli.getElementsByTagName('LI'))
    .sort((a, b) => a.textContent.localeCompare(b.textContent))
    .forEach((li) => {
      uli.appendChild(li);
      // Keep Default Land Unit as the first symbol in the list
      if (li.dataset.value === 'Default Land Unit') {
        uli.prepend(li);
      }
      // Keep "none" as the first symbol in the list for Mod1/Mod2
      if (li.dataset.value === 'None') {
        uli.prepend(li);
      }
    });

  setTimeout(() => {
    selectMod1.value = 'None';
    selectMod2.value = 'None';
  }, 300);
}

// This async iterator will alphabetically sort the symbol list, mod1 and mod2 on page load
// This seems to be a more reliable way of sorting the lists on page load, previously I was using DOMContentLoaded with setTimeout which wasn't great
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/for-await...of
const asyncIterable = {
  [Symbol.asyncIterator]() {
    return {
      i: 0,
      next() {
        if (this.i < 3) {
          return Promise.resolve({ value: this.i += 1, done: false });
        }
        return Promise.resolve({ done: true });
      },
    };
  },
};


(async function () {
  const listsToSort = ['.symbol-list', '.mod1-list', '.mod2-list'];
  for await (const num of asyncIterable) {
    sortSymbolObjectList(`${listsToSort[num - 1]}`);
  }
}());


window.enableFlyingOutline = enableFlyingOutline;

export {
  flyingSwitch, MainMS, selectAffiliation,
};
